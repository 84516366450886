body {
    background-color: #5b6334;
} 

.logo-container {
    margin-top:20px;
    .logo-image {
        margin-left:auto;
        margin-right:auto;
        max-width:600px;
        padding:20px;
        img {
            width:100%;
        }
    }
}